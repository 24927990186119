import { IEventElementDisplayDrawer } from '@demiplane-dev/types';
import { Box, Paper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider } from '@mui/material/styles';
import { ElementDisplay } from 'Components/ElementDisplay';
import { useSlsElementToolTipQuery } from 'Graphql/sls-element-tool-tip.query.generated';
import { useRxJsEmitter } from 'Hooks/RxJsEmitter';
import { useEffect, useState } from 'react';
import { NexusTheme } from '../../theme/nexusTheme';
import { ElementToolTipHeader } from './components/ElementToolTipHeader';
import { PrimerToolTipDisplay } from './components/PrimerToolTipDisplay';
import { PrimerToolTipHeader } from './components/PrimerToolTipHeader';
import { useStyles } from './styles';
import { mapResultToCard } from 'Components/Cards/mapResultToCard';
import { useQuery } from 'urql';
import {
  AlgoliaSearchEntitledDocument,
  AlgoliaSearchEntitledQuery,
} from 'Graphql/algolia-search-entitled.query.generated';

interface IElementToolTipProps {
  slug: string;
  category: string;
  nexusId: string;
  preloaded?: boolean;
  allowUnauthenticatedAccess: boolean;
  isFreelyEntitled: boolean;
}

export const ElementToolTip = ({
  slug,
  category,
  nexusId,
  preloaded,
  allowUnauthenticatedAccess,
  isFreelyEntitled,
}: IElementToolTipProps) => {
  const [overflowing, setOverflowing] = useState(false);
  const matchesWidth = useMediaQuery('(min-width:1024px)');
  const { emit } = useRxJsEmitter();
  const [themeConfig, setThemeConfig] = useState<any>();

  const onOverflow = () => {
    setOverflowing(true);
  };

  const [elementToolTipResult] = useSlsElementToolTipQuery({
    variables: {
      slug,
      category,
      nexusId,
    },
    requestPolicy: preloaded ? 'cache-first' : 'network-only',
  });

  const { data: elementToolTipData, fetching: elementToolTipLoading } =
    elementToolTipResult;

  // load and grab the config.theme if it exists
  useEffect(() => {
    if (
      !!elementToolTipData?.slsElementToolTip?.data?.elementDisplayVersion &&
      !!elementToolTipData?.slsElementToolTip?.data?.elementDisplayVersion
        .elementDisplayByElementDisplayId &&
      elementToolTipData?.slsElementToolTip?.data?.elementDisplayVersion
        .elementDisplayByElementDisplayId.nexus
    ) {
      const nexusConfig =
        elementToolTipData?.slsElementToolTip?.data?.elementDisplayVersion
          .elementDisplayByElementDisplayId.nexus.config_data;
      setThemeConfig(nexusConfig.theme);
    }
  }, [elementToolTipData]);

  const handleReadMoreClick = () => {
    emit<IEventElementDisplayDrawer>('openElementDisplayDrawer', {
      nexusId,
      category,
      slug,
    });
  };

  const elementDisplayVersion = !elementToolTipLoading
    ? elementToolTipData?.slsElementToolTip?.data?.elementDisplayVersion
    : undefined;
  const listingConfig = !elementToolTipLoading
    ? elementDisplayVersion?.elementDisplayByElementDisplayId?.nexus
        ?.listing_data
    : undefined;

  let isCard = false;
  let cardViewConfig = [];
  if (!!listingConfig && listingConfig.length > 0) {
    cardViewConfig = listingConfig.filter(
      (config: any) =>
        config.cardView?.enabled &&
        config.cardView?.elementCategory === category
    );
    isCard = !!cardViewConfig && cardViewConfig.length > 0;
  }
  const [algoliaSearchEntitledResponse] = useQuery<AlgoliaSearchEntitledQuery>({
    query: AlgoliaSearchEntitledDocument,
    variables: {
      entitlementCategory: category,
      indexName: `${elementDisplayVersion?.elementDisplayByElementDisplayId?.nexus?.slug}_${category}`,
      query: '',
      searchParameters: {
        filters: `objectID:${elementDisplayVersion?.elementDisplayByElementDisplayId?.id}`,
        hitsPerPage: 100,
        page: 0,
      },
    },
    pause: !elementDisplayVersion,
  });
  const algoliaElement =
    algoliaSearchEntitledResponse?.data?.algoliaSearchEntitled?.result?.[0] ||
    null;

  const classes = useStyles({
    overflowing: true,
    isCard,
  });

  return (
    <>
      <ThemeProvider theme={NexusTheme}>
        {matchesWidth &&
          !(
            !elementToolTipLoading &&
            !elementToolTipData?.slsElementToolTip?.data
              ?.elementDisplayVersion &&
            !elementToolTipData?.slsElementToolTip?.data?.primerVersion
          ) && (
            <Paper
              className={`${classes.toolTip} tooltip-hovercard-container`}
              classes={{
                root: classes.toolTip,
                rounded: classes.toolTip,
                elevation1: classes.toolTip,
              }}
              sx={{
                minHeight: !!elementToolTipData ? '10rem' : '34.625rem',
              }}
            >
              <>
                {!elementToolTipLoading &&
                  !!elementToolTipData?.slsElementToolTip?.data && (
                    <>
                      {!!elementToolTipData.slsElementToolTip.data
                        .primerVersion && (
                        <>
                          <PrimerToolTipHeader
                            primerVersion={
                              elementToolTipData.slsElementToolTip.data
                                .primerVersion
                            }
                          />
                          <Box
                            style={{ marginTop: '-1rem' }}
                            className={classes.elementDisplayContainer}
                          >
                            <ThemeProvider theme={NexusTheme}>
                              <PrimerToolTipDisplay
                                primerVersion={
                                  elementToolTipData.slsElementToolTip.data
                                    .primerVersion
                                }
                                maxHeight={'410'}
                                onOverflow={onOverflow}
                                showElementImage={false}
                                type='tooltip'
                              />
                            </ThemeProvider>
                          </Box>
                        </>
                      )}
                      {!!elementDisplayVersion && !isCard && (
                        <>
                          <ElementToolTipHeader
                            element={elementDisplayVersion}
                          />
                          <Box
                            style={{ marginTop: '-1rem' }}
                            className={classes.elementDisplayContainer}
                          >
                            <ThemeProvider theme={NexusTheme}>
                              <ElementDisplay
                                element={elementDisplayVersion}
                                maxHeight={'410'}
                                onOverflow={onOverflow}
                                showElementImage={false}
                                type='tooltip'
                                allowUnauthenticatedAccess={
                                  allowUnauthenticatedAccess
                                }
                                isFreelyEntitled={isFreelyEntitled}
                              />
                            </ThemeProvider>
                          </Box>
                        </>
                      )}
                      {isCard && algoliaElement && (
                        <>
                          {mapResultToCard(
                            algoliaElement,
                            cardViewConfig[0],
                            false,
                            0
                          )}
                        </>
                      )}
                      {overflowing && (
                        <Box
                          className={`${classes.readMoreFooter} element-display-tooltip-read-more-outer`}
                          sx={[
                            (theme) => ({
                              background: !!themeConfig
                                ? themeConfig.palette.base.base3
                                : theme.palette.base.base3,
                            }),
                          ]}
                        >
                          <Box
                            className={`${classes.readMoreContainer} element-display-tooltip-read-more-inner`}
                            onClick={handleReadMoreClick}
                          >
                            <Box
                              className={`${classes.readMore} element-tooltip-read-more-text`}
                            >
                              Click to see more
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
              </>
            </Paper>
          )}
      </ThemeProvider>
    </>
  );
};
