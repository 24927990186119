import { ThemeProvider, Tooltip, useMediaQuery } from '@mui/material';
import { Box, Theme } from '@mui/system';
import { useSlsElementToolTipQuery } from 'Graphql/sls-element-tool-tip.query.generated';
import { ReactElement, useState, Fragment } from 'react';
import { ElementToolTip } from './ElementToolTip';
import { NexusTheme } from 'src/theme/nexusTheme';

interface IManagedElementTooltipProps {
  slug: string;
  category: string;
  nexusId: string;
  preloaded?: boolean;
  children: ReactElement;
}

const ManagedElementTooltipBase = ({
  slug,
  category,
  nexusId,
  children,
}: IManagedElementTooltipProps) => {
  const [hovering, setHovering] = useState(false);

  const [elementToolTipResult] = useSlsElementToolTipQuery({
    variables: {
      slug,
      category,
      nexusId,
    },
    requestPolicy: 'cache-first',
    pause: !hovering,
  });
  // console.log('elementToolTipResult', elementToolTipResult);

  const isLoading = elementToolTipResult?.fetching;
  const TooltipComp = isLoading ? Fragment : Tooltip;

  // Determine configurable unauthenticated access/free entitlement
  let allowUnauthenticatedAccess = false;
  const elementDisplayVersion = !elementToolTipResult?.fetching
    ? elementToolTipResult?.data?.slsElementToolTip?.data?.elementDisplayVersion
    : undefined;
  const primerVersion = !elementToolTipResult?.fetching
    ? elementToolTipResult?.data?.slsElementToolTip?.data?.primerVersion
    : undefined;
  let nexusUnentitledAccessType = undefined;
  if (elementDisplayVersion) {
    nexusUnentitledAccessType =
      elementDisplayVersion.elementDisplayByElementDisplayId.nexus
        .unentitled_access_type;
  } else {
    nexusUnentitledAccessType =
      primerVersion?.primer.nexus.unentitled_access_type;
  }
  if (!!nexusUnentitledAccessType) {
    if (nexusUnentitledAccessType.toUpperCase() === 'ALL') {
      allowUnauthenticatedAccess = true;
    }

    if (nexusUnentitledAccessType.toUpperCase() === 'SOURCE') {
      let openSourcesExists: any[] | undefined = undefined;
      if (elementDisplayVersion) {
        openSourcesExists =
          elementDisplayVersion?.elementDisplayByElementDisplayId?.element_display_sources?.filter(
            (source: any) => source.source.unentitled_access
          );
      } else {
        openSourcesExists = primerVersion?.primer?.primer_sources?.filter(
          (source: any) => source.source.unentitled_access
        );
      }
      if (openSourcesExists && openSourcesExists?.length > 0) {
        allowUnauthenticatedAccess = true;
      }
    }
  }

  const isFreelyEntitled =
    elementToolTipResult?.data?.slsElementToolTip?.data?.freeEntitlements &&
    elementToolTipResult?.data?.slsElementToolTip?.data?.freeEntitlements
      .length > 0
      ? true
      : false;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const handleFocus = () => {
    if (!isMobile) {
      setHovering(true);
    }
  };

  return (
    <Box
      onMouseOver={handleFocus}
      onMouseOut={() => setHovering(false)}
      sx={{
        display: 'inline',
        cursor: isLoading ? 'progress' : 'pointer',
      }}
      className={`element-tooltip-wrapper${
        isLoading ? 'element-tooltip-wrapper--loading' : ''
      }`}
    >
      <TooltipComp
        classes={{
          tooltip: `tooltip-container`,
        }}
        open={hovering}
        componentsProps={{
          popper: {
            sx: {
              zIndex: '1205 !important',
            },
          },
          tooltip: {
            sx: {
              maxWidth: 'min(100vw, 900px)',
              overflow: 'scroll',
            },
          },
        }}
        placement='top'
        title={
          <ElementToolTip
            slug={slug}
            category={category}
            nexusId={nexusId}
            preloaded
            allowUnauthenticatedAccess={allowUnauthenticatedAccess}
            isFreelyEntitled={isFreelyEntitled}
          />
        }
      >
        {children}
      </TooltipComp>
    </Box>
  );
};

export const ManagedElementTooltip = (props: IManagedElementTooltipProps) => {
  return (
    <ThemeProvider theme={NexusTheme}>
      <ManagedElementTooltipBase {...props} />
    </ThemeProvider>
  );
};
