import * as Types from './types';

import { gql } from 'urql';
export type ElementSourceFieldsFragment = { __typename?: 'demiplane_source', id: string, slug: string, name: string, sku: string, source_state: { __typename?: 'demiplane_source_state', name: string }, products: Array<{ __typename?: 'demiplane_product', nexus_asset_slug?: string | null }> };

export const ElementSourceFieldsFragmentDoc = gql`
    fragment ElementSourceFields on demiplane_source {
  id
  slug
  name
  sku
  source_state {
    name
  }
  products {
    nexus_asset_slug
  }
}
    `;