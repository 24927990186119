import * as Types from './types';

import { gql } from 'urql';
import { ElementElementDisplayVersionNexusFieldsFragmentDoc } from './element-element-display-version-nexus.fragment.generated';
export type ElementElementDisplayFieldsFragment = { __typename?: 'demiplane_element_display', id: string, category: string, nexus_id: string, slug: string, sku?: string | null, nexus: { __typename?: 'demiplane_nexus', id: string, name: string, footer_data?: any | null, config_data?: any | null, asset_slug: string, slug: string, call_to_action_data?: any | null, early_access: boolean, listing_data?: any | null, unentitled_access_type?: string | null, nexus_landings: Array<{ __typename?: 'demiplane_nexus_landing', html: string }> }, element_display_sources: Array<{ __typename?: 'demiplane_element_display_source', source: { __typename?: 'demiplane_source', id: string, slug: string, name: string, sku: string, unentitled_access: boolean, source_state: { __typename?: 'demiplane_source_state', name: string } } }> };

export const ElementElementDisplayFieldsFragmentDoc = gql`
    fragment ElementElementDisplayFields on demiplane_element_display {
  id
  category
  nexus_id
  slug
  sku
  nexus {
    ...ElementElementDisplayVersionNexusFields
  }
  element_display_sources(order_by: {source: {id: asc}}) {
    source {
      id
      slug
      name
      sku
      unentitled_access
      source_state {
        name
      }
    }
  }
}
    ${ElementElementDisplayVersionNexusFieldsFragmentDoc}`;